import { graphql } from 'gatsby'
import React from 'react'
import NotFound from '../components/notFound/NotFound'
import { SEO } from '../components/shared'
const PageNotFound = () => {

  return (
    <>
      <SEO title='Erreur 404' description='Erreur 404'/>
      <NotFound />
    </>
  )
}

export default PageNotFound