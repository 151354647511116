import React from 'react'
import styled from 'styled-components'
import { useDictionaryQuery, useLang, useLocale } from '../../hooks'
import { colors, desktopBreakpoint, desktopVw, fonts, mobileVw } from '../../styles'
import { Button } from '../shared'
const NotFound = () => {
  const { notFoundTitle, notFoundSubtitle, notFoundButton, notFoundAdditionalLink, notFoundAdditionalLinkText } = useDictionaryQuery()
  const lang = useLang()
  const locale = useLocale()
  return (
    <>
      <Container>
        <NotFoundContainer>
          <NotFoundHeading>
            {notFoundTitle.split(',')[0]}
            <span>
              {notFoundTitle.split(',')[1]}
            </span>
          </NotFoundHeading>
          <NotFoundSubTitle>
            {notFoundSubtitle.split(',')[0]}
            <span>
              {notFoundSubtitle.split(',')[1]}
            </span>
          </NotFoundSubTitle>
          <ButtonContainer>
            <NotFoundButton option={!!notFoundAdditionalLinkText && locale === 'fr'} text={notFoundButton} to={`/${lang}`} />
            {notFoundAdditionalLinkText && notFoundAdditionalLink &&
              <NotFoundButton text={notFoundAdditionalLinkText} to={`/${lang}${notFoundAdditionalLink}`} />
            }
          </ButtonContainer>

        </NotFoundContainer>
      </Container>
    </>
  )
}
const Container = styled.div`
background-color: ${colors.white};
color: ${colors.black};
span{
  color: ${colors.mailleGold};
}
font-family:${fonts.splandor};
`
const NotFoundContainer = styled.div`
width:90%;
margin:0 auto;
text-align:center;
@media (min-width: ${desktopBreakpoint}) {
  width:50%;
  margin:0 auto;
  padding: auto 0;
}
`
const NotFoundHeading = styled.h1`
padding-top:${mobileVw(40)};
font-size: ${mobileVw(30)};
@media (min-width: ${desktopBreakpoint}){
  padding-top:${desktopVw(80)};
  font-size: ${desktopVw(45)};
}
`

const ButtonContainer = styled.div`
  display:flex;
  @media (max-width: ${desktopBreakpoint}){
    margin-top:${mobileVw(25)};
    flex-direction:column;
  }
`

const NotFoundSubTitle = styled.h2`
padding-top:${mobileVw(40)};
font-size: ${mobileVw(27)};
@media (min-width: ${desktopBreakpoint}){
  padding-top:${desktopVw(80)};
  font-size: ${desktopVw(40)};
}
`
const NotFoundButton = styled(Button)`
margin:${mobileVw(10)} 0;
@media (min-width: ${desktopBreakpoint}){
  margin:${desktopVw(80)} ${desktopVw(10)}; 
}
`

export default NotFound